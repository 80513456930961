import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import styles from "./index.module.css"

const IndexPage = () => (
  <Layout>
    <div className={styles.container}>
      <link
        href="https://fonts.googleapis.com/css?family=Playfair+Display&display=swap"
        rel="stylesheet"
      />
      <header>Katie Lovell</header>
      <div className={styles.callToAction}>
        <Link to="/pilates" className={styles.ctaBtn}>
          Pilates
        </Link>
        <Link to="/vaginal-steaming" className={styles.ctaBtn}>
          Vaginal Steaming
        </Link>
        <Link to="/doula-services" className={styles.ctaBtn}>
          Doula Services
        </Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
